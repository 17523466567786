<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@onSubmit="() => onResend()"
			@goTo="path => $router.push(path)"
	/>
</template>
<script>
import Template from '../components/templates/VerifyEmailStep/VerifyEmailStep';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
		async onResend() {
			const {resendVerificationEmail} = await this.actions.resendVerificationEmail({
				email: this.$route.query?.email,
				userId: this.$route.query?.userId,
        userType: this.$route.query?.type
			});
			if (resendVerificationEmail) {
				this.actions.alert.showSuccess({message: 'Resent verification email successfully!'});
			}
		}
	},
	data() {
		return {
			params: c.verifyEmailStep,
			isLoading: false,
		}
	},
	mounted() {
		console.log(this.$route.query?.email);
		if (!this.$route.query?.email) {
			this.actions.alert.showError({message: 'Invalid email address. Please try again later'});
			this.$router.go(-1);
		}
	}
}
</script>
